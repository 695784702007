<template>
  <div class="m-contact">
    <ASectionTitle>
      <template v-slot:icon>
        <!-- v-if="whiteIcon"  -->
        <!-- v-else -->
        <div class="hedsetIcon" alt="Ícone fale conosco"></div>
        <!-- <img src="../../assets/fale_conosco_v2.svg" alt="Ícone fale conosco" /> -->
      </template>
      <template v-slot:title>
        <router-link
          style="color: #FFFFFF; font-size: 12px !important;"
          class="textFaleConosco"
          title="Fale Conosco"
          to="/fale-conosco"
        >
          Fale Conosco
        </router-link>
      </template>
    </ASectionTitle>
  </div>
  <!-- <div style="display: flex; justify-content: center;">
    <div style="border: 1px solid #70CDED; border-radius: 5px; display: flex; justify-content: center; width: 420px;" class="cardContato">
      <span style="padding: 20px; color: #70CDED; letter-spacing: 5px;" class="numberContato">0800 264 0001</span>
    </div>
  </div> -->
</template>

<script>
import ASectionTitle from "@/components/molecules/SectionTitle.vue";

export default {
  name: "NewContact",
  props: {
    whiteIcon: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  components: {
    ASectionTitle
  }
};
</script>

<style>
.hedsetIcon {
  background-image: url("../../assets/white-headset.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  height: 40px;
  right: 12px;
  top: calc(50% + 2px);
  width: 100%;
}

.hedsetIcon-white {
  background-image: url("../../assets/white-headset.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  height: 40px;
  right: 12px;
  top: calc(50% + 2px);
  width: 100%;
}
</style>
